var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lead-info" }, [
    _vm.isContinueApplicationLead
      ? _c(
          "div",
          {
            key: "continueApplication",
            staticClass:
              "lead-application lead-application--continue-application",
          },
          [_c("p", [_vm._v(_vm._s(_vm.content.continueApplication))])]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isAddCoApplicantLead
      ? _c(
          "div",
          {
            key: "addCoApplicant",
            staticClass: "lead-application lead-application--add-co-applicant",
          },
          [_c("p", [_vm._v(_vm._s(_vm.content.addCoApplicant))])]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }