var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "single-step" }, [
    _c(
      "div",
      {
        class: [
          "application",
          {
            "application--local": _vm.LOCAL,
            "application--with-background": _vm.withBackground || _vm.LOCAL,
            "application--se": _vm.SWEDEN,
            "application--no": _vm.NORWAY,
            "application--fi": _vm.FINLAND,
            "application--one-column": _vm.oneColumn,
            "application--on-beige-background": _vm.onBeigeBackground,
            "application--on-article-page": _vm.onArticlePage,
            "application--on-partner-page": _vm.onPartnerPage,
            "application--on-campaign-page": _vm.onCampaignPage,
            "application--on-marketing-landing": _vm.onMarketingLanding,
          },
        ],
      },
      [
        _vm.showElement("title")
          ? _c("h3", { domProps: { innerHTML: _vm._s(_vm.formTitle) } })
          : _vm._e(),
        _vm._v(" "),
        _vm.showReApply ? _c("re-apply", { key: "re-apply" }) : _vm._e(),
        _vm._v(" "),
        _vm.showTranslation
          ? _c("language-translation", { key: "language-translation" })
          : _vm._e(),
        _vm._v(" "),
        _vm.showLeadInfo ? _c("lead-info", { key: "lead-info" }) : _vm._e(),
        _vm._v(" "),
        _vm.showErrors ? _c("field-errors", { key: "field-errors" }) : _vm._e(),
        _vm._v(" "),
        _c("steps", {
          key: "steps",
          attrs: {
            "on-beige-background":
              _vm.onBeigeBackground || _vm.onArticlePage || _vm.onPartnerPage,
            "on-campaign-page": _vm.onCampaignPage,
            "show-steps": _vm.showSteps,
          },
          on: {
            loaded: function ($event) {
              return _vm.loaded("steps")
            },
            "changed-step": _vm.changedStep,
          },
        }),
        _vm._v(" "),
        _c("buttons", {
          key: "buttons",
          attrs: {
            "submit-label": _vm.submitLabel,
            "on-campaign-page": _vm.onCampaignPage,
          },
          on: {
            submit: function ($event) {
              return _vm.goToNextStep()
            },
          },
        }),
        _vm._v(" "),
        _vm.showTerms
          ? _c("terms", {
              key: "terms",
              attrs: { "submit-label": _vm.submitLabel },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showUsps
          ? _c("usps", { key: "usps", attrs: { usps: _vm.content.usps } })
          : _vm._e(),
        _vm._v(" "),
        !_vm.hideElement("disclaimer") ? _c("disclaimer") : _vm._e(),
        _vm._v(" "),
        _c("trustpilot"),
        _vm._v(" "),
        _c("sticky-cta", {
          attrs: { label: _vm.submitLabelStickyCta, target: ".application" },
        }),
        _vm._v(" "),
        _vm.showDebug ? _c("debug-menu", { key: "debug-menu" }) : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.LOCAL
      ? _c("div", { staticStyle: { "padding-bottom": "1500px" } })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }