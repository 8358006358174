<template>
  <zg-transition-block>
    <div
      v-if="terms"
      key="terms"
      class="terms"
      v-html="terms"
    />
  </zg-transition-block>
</template>

<script>
  import { replaceBrandVars } from '@ocp-zmarta/zmarta-cl'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Terms',
    components: {
      ZgTransitionBlock: () => import(/* webpackChunkName: 'zc/zg-transition-block' */
        '@zc/components/ZgTransitionBlock/ZgTransitionBlock')
    },
    props: {
      submitLabel: {
        type: String,
        required: false,
        default: null
      }
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('translations', ['getLocale']),
      ...mapGetters('form', ['getForm']),
      hasCoApplicant () {
        return this.getForm?.global?.hasCoApplicant?.value
      },
      terms () {
        let terms = this.getContent?.application?.singleStep?.appTerms
        terms = replaceBrandVars({
          brand: this.BRAND,
          market: this.MARKET,
          vertical: this.VERTICAL,
          locale: this.getLocale,
          content: terms,
          form: this.getForm
        })

        terms = terms?.replace('{submitLabel}', this.submitLabel)

        return this.hasCoApplicant
          ? terms?.replace('{coApplicant}', this.getContent?.application?.singleStep?.personalDetails?.andCoApplicant)
          : terms?.replace('{coApplicant}', '')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .terms {
    display: block;

    ::v-deep p {
      @include type-body-s;

      color: color(gray-500);
      margin: rhythm(xsmall) 0 0;
    }
  }
</style>
