var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("zg-transition-block", [
    _vm.terms
      ? _c("div", {
          key: "terms",
          staticClass: "terms",
          domProps: { innerHTML: _vm._s(_vm.terms) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }