<template>
  <div class="lead-info">
    <div
      v-if="isContinueApplicationLead"
      key="continueApplication"
      class="lead-application lead-application--continue-application"
    >
      <p>{{ content.continueApplication }}</p>
    </div>

    <div
      v-if="isAddCoApplicantLead"
      key="addCoApplicant"
      class="lead-application lead-application--add-co-applicant"
    >
      <p>{{ content.addCoApplicant }}</p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'LeadInfo',
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('form', ['getForm']),
      content () {
        return this.getContent?.elements?.leadInfo ?? {}
      },
      isAddCoApplicantLead () {
        return this.getForm?.global?.leadType?.value === 'addCoApplicant'
      },
      isContinueApplicationLead () {
        return this.getForm?.global?.leadType?.value === 'continueApplication'
      }
    }
  }
</script>
