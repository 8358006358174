<template>
  <div
    v-if="stepComponent"
    key="steps"
    :class="['steps', {
      'lead-application lead-application--continue-application': isContinueApplicationLead,
      'lead-application lead-application--add-co-applicant': isAddCoApplicantLead
    }]"
  >
    <div
      v-for="(step, index) in steps"
      :id="step.name"
      :key="index"
      :class="['step', {
        'step--border': showStep(index) && step.border
      }]"
    >
      <zg-transition-block @after-transition="changedStepAction">
        <component
          :is="stepComponent(index)"
          v-if="showStep(index)"
          v-show="!stepValues(index).hidden"
          :key="stepComponent(index)"
          :current-step="currentStep"
          :current-step-name="currentStepName"
          :on-beige-background="onBeigeBackground"
          :on-campaign-page="onCampaignPage"
          @loaded="loaded('step')"
          @submit="goToNextStep"
        />
      </zg-transition-block>
    </div>
  </div>
</template>

<script>
  import { arraysAreEqual } from '@ocp-zmarta/zmarta-cl'
  import { mapActions, mapGetters } from 'vuex'
  import Step1Fi from '../fi/Step1FI'
  import Step2Fi from '../fi/Step2FI'
  import Step3Fi from '../fi/Step3FI'
  import Step4Fi from '../fi/Step4FI'
  import Step1No from '../no/Step1NO'
  import Step1Se from '../se/Step1SE'
  import Step2Se from '../se/Step2SE'
  import Step3Se from '../se/Step3SE'

  export default {
    name: 'Steps',
    components: {
      Step1Se,
      Step2Se,
      Step3Se,
      Step1No,
      Step1Fi,
      Step2Fi,
      Step3Fi,
      Step4Fi,
      ZgTransitionBlock: () => import(/* webpackChunkName: 'zc/zg-transition-block' */
        '@zc/components/ZgTransitionBlock/ZgTransitionBlock')
    },
    props: {
      showSteps: {
        type: Boolean,
        required: false,
        default: false
      },
      onBeigeBackground: {
        type: Boolean,
        required: false,
        default: false
      },
      onCampaignPage: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      loadComponents: {
        needed: [
          'mounted',
          'step'
        ],
        loaded: [],
        finished: false
      }
    }),
    computed: {
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/formSteps', ['steps', 'visited', 'currentStep', 'currentStepName', 'previousStep', 'valid']),
      isAddCoApplicantLead () {
        return this.getForm?.global?.leadType?.value === 'addCoApplicant'
      },
      isContinueApplicationLead () {
        return this.getForm?.global?.leadType?.value === 'continueApplication'
      }
    },
    async mounted () {
      await this.validateFields()

      if (this.currentStep > 2) {
        await this.validateLockedFields('applicant')

        if (this.getForm?.global?.hasCoApplicant?.value) {
          await this.validateLockedFields('coApplicant')
        }
      }

      this.loaded('mounted')
    },
    methods: {
      ...mapActions('cl/formSteps', ['goToNextStep', 'changeStep']),
      ...mapActions('form', ['validateFields', 'validateField']),
      loaded (component) {
        if (this.loadComponents.finished) return
        this.loadComponents.loaded.push(component)

        if (!arraysAreEqual(
          this.loadComponents.needed,
          this.loadComponents.loaded
        )) return

        this.loadComponents.finished = true
        this.$emit('loaded')
      },
      stepComponent (index) {
        return `step${index + 1}-${this.MARKET}`
      },
      stepValues (index) {
        return this.steps[index]
      },
      showStep (index) {
        if (index === 0) return true
        if (!this.showSteps) return false

        return this.currentStep === (index + 1) || this.visited.includes(index + 1)
      },
      async changedStepAction () {
        this.$emit('changed-step')
      },
      async validateLockedFields (fieldGroup) {
        await this.validateField({
          group: fieldGroup,
          field: 'ssn',
          value: this.getForm?.[fieldGroup]?.ssn?.value,
          touched: true
        })
        await this.validateField({
          group: fieldGroup,
          field: 'email',
          value: this.getForm?.[fieldGroup]?.email?.value,
          touched: true
        })
        await this.validateField({
          group: fieldGroup,
          field: 'phone',
          value: this.getForm?.[fieldGroup]?.phone?.value,
          touched: true
        })
        await this.validateField({
          group: 'global',
          field: 'appNo',
          value: this.getForm?.global?.appNo?.value,
          touched: true
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .step {
    position: relative;

    &--border {
      padding: rhythm(large) 0 0;
      margin: rhythm(medium) 0 0;

      &::before {
        position: absolute;
        top: 0;
        left: - rhythm(medium);
        right: - rhythm(medium);
        height: rem(2px);
        background-color: color(beige);
        content: '';
      }
    }
  }

  .lead-application {
    padding: rhythm(small) 0 0;
    border-top: 1px solid color(beige);

    & > div {
      width: 100%;
    }
  }
</style>
