<template>
  <div class="add-co-applicant-variation">
    <label for="global-showCoApplicant">
      <span>
        <span>{{ content.title }}</span>
        <span class="is-required">*</span>
      </span>

      <span>{{ content.text }}</span>
    </label>

    <div
      :class="['radio', {
        'radio--fi': FINLAND
      }]"
    >
      <zg-radio
        v-model="val"
        :error="error"
        :invalid="invalid"
        :options="content.options"
        :value="fieldData.value"
        full-width
        name="global-showCoApplicant"
        no-margin
        @input="toggle($event)"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'AddCoApplicantButtonVariation',
    components: {
      ZgRadio: () => import(/* webpackChunkName: 'zc/zg-radio' */
        '@zc/components/ZgRadio/ZgRadio')
    },
    data () {
      return {
        val: undefined
      }
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/analytics', ['getStep']),
      content () {
        return this.getContent?.elements?.addCoApplicantVariation ?? {}
      },
      fieldData () {
        return this.getForm?.global?.showCoApplicant ?? {}
      },
      error () {
        return !this.invalid ? null : this.content.error
      },
      invalid () {
        return !!(
          !this.fieldData?.valid &&
          this.fieldData?.touched &&
          this.fieldData?.visibleValidation &&
          !this.fieldData?.disabled
        )
      }
    },
    mounted () {
      if (this.fieldData.value !== undefined) {
        this.val = this.fieldData.value ? 'yes' : 'no'
      }
    },
    methods: {
      ...mapActions('form', ['setField']),
      ...mapActions('cl/analytics', ['viewTracking']),
      async toggle (value) {
        await this.setField({
          group: 'global',
          field: 'hasCoApplicant',
          touched: true,
          value
        })

        await this.setField({
          group: 'global',
          field: 'showCoApplicant',
          touched: true,
          valid: true,
          value
        })

        this.ZGA.event.form.fieldClick({
          step: this.getStep,
          fieldName: 'co-applicant',
          fieldType: 'radio',
          fieldValue: value === 'true' ? 'yes' : 'no'
        })

        if (value === 'true') {
          await this.viewTracking({ label: 'co-applicant' })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .add-co-applicant-variation {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rhythm(2.4);
    margin: 0 0 rhythm();

    @include mq(medium) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    label,
    .radio {
      width: 100%;

      @include mq(medium) {
        width: 50%;
      }
    }

    label {
      & > span:first-child {
        @include type-title-xs;

        font-size: rhythm(small);
        line-height: 1;
        color: color(black);
        font-weight: 600;
        margin: 0 0 rhythm(xsmall);
      }

      & > span:last-child {
        @include type-body-xs;

        display: block;
        line-height: 1;
        margin: 0;
      }

      .is-required {
        color: color(error-500);
      }
    }

    .radio {
      width: 100%;

      @include mq(medium) {
        width: 50%;
      }
    }
  }
</style>
